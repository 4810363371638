import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from './Styles/Text';

function FaqItem(props) {
  const { question, answer } = props;
  const [expanded, setExpanded] = useState(false);

  const answerDiv = useRef(null);

  const toggleAnswer = (e) => {
    setExpanded(!expanded);
  };

  return (
    <Wrap>
      <Question aria-expanded={expanded} onClick={(e) => toggleAnswer(e)}>
        {question}
      </Question>
      <Answer className="answer" ref={answerDiv}>
        <Text mb={0}>{answer}</Text>
      </Answer>
    </Wrap>
  );
}

const Wrap = styled.div`
  margin-bottom: 8px;
`;

const Question = styled.div`
  padding: 16px 22px;
  font-size: 1.2em;
  position: relative;
  background: #f6f9fc;
  border-radius: 2px;
  font-weight: bold;
  cursor: pointer;
  &::after {
    content: '+';
    display: inline-block;
    width: 32px;
    position: absolute;
    right: 0;
    font-size: 1.2em;
    font-weight: bold;
  }
  &[aria-expanded='true']::after {
    content: '-';
  }
  &[aria-expanded='true'] ~ .answer {
    transform: scale(1, 1);
    visibility: visible;
    display: block;
    padding: 16px;
  }
`;

const Answer = styled.div`
  transform: scale(1, 0);
  transform-origin: top;
  transition: all 250ms ease-in-out;
  display: none;
  padding: 16px;
`;

FaqItem.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
};

export default FaqItem;
