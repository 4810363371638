import React, { Component, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Breadcrumbs = (props) => {
  const getPages = (pathname) => {
    return pathname.split('/').filter((string) => string.length != 0);
  };
  const pageArr = getPages(props.location.pathname);

  return (
    <Wrapper aria-label="breadcrumb">
      <Link to="/">
        <span>Home</span>
      </Link>
      {pageArr.map((page) => (
        <Link to={`/${page}`}>
          <span> / {page.replace('-', ' ')}</span>
        </Link>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  font-size: 0.85em;
  margin-bottom: 1.5em;
  text-transform: capitalize;
`;

export default Breadcrumbs;
