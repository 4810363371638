import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

function SingleLineTextWithIcon({ className, icon, text, link }) {
  return (
    <Wrap className={className}>
      {icon && icon}
      {link && (
        <Link to={link}>
          <span>{text}</span>
        </Link>
      )}
      {!link && <span>{text}</span>}
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  max-width: 75ch;
  svg {
    margin-right: 0.5em;
    margin-top: 0.2em;
    flex: 0 0 1.2em;
    fill: var(--primary);
  }
  a:hover {
    color: var(--secondary);
    text-decoration: underline;
  }
`;

export default SingleLineTextWithIcon;
