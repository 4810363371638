import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { flexbox, space, layout } from 'styled-system';

const Flex = styled.div`
  display: flex;
  ${flexbox};
  ${space};
  ${layout};
`;
Flex.propTypes = { ...flexbox.propTypes, display: PropTypes.string };

export default Flex;
