
		import React from 'react';

		function AngleDownIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 384, 512">
					<path d="M191.1 352c-1.1 0-3.969-.7344-5.531-2.219L34.47 205.8c-3.187-3.047-3.312-8.125-.25-11.31c3.094-3.188 8.156-3.281 11.31-.25l146.5 138.7l146.5-138.7c3.219-3.031 8.281-2.938 11.31 .25c3.062 3.188 2.937 8.266-.25 11.31l-151.1 144C195.1 351.3 193.1 352 191.1 352z" />
				</svg>
			);
		}

		export default AngleDownIcon;
	