import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Section from './Section';
import Inner from './Styles/Inner';
import Flex from './Styles/Flex';
import Grid from './Styles/Grid';
import Box from './Styles/Box';
import Button from '../components/Buttons/ButtonSecondary';

const Tabs = (props) => {
  const [activeTab, setTab] = useState(0);
  const { data, value } = props;

  const handleChange = (index) => {
    setTab(index);
  };

  return (
    <TabSection gridTemplateRows={'auto 2fr'}>
      <TabNav role="tablist" aria-label="Sample Tabs">
        {data.map((tab, index) => (
          <TabButton
            key={index}
            flex="1"
            flexWrap="wrap"
            onClick={() => handleChange(index)}
            className={index == activeTab && 'active'}
            role="tab"
          >
            <span>{tab.label}</span>
          </TabButton>
        ))}
      </TabNav>
      <TabContentWrap>
        {data.map((tab, index) => (
          <TabContent
            key={index}
            active={activeTab === index && true}
            role="tabpanel"
            tabindex="0"
            aria-labelledby="tab-1"
            tabindex="-1"
          >
            {tab.content.heading && <h3>{tab.content.heading}</h3>}
            {tab.content.content && <p>{tab.content.content}</p>}
          </TabContent>
        ))}
      </TabContentWrap>
    </TabSection>
  );
};

const TabSection = styled(Grid)`
  padding: 0;
`;

const TabNav = styled(Flex)`
  display: flex;
  align-items: stretch;
  gap: 2em;
`;

const TabButton = styled(Button)`
  cursor: pointer;
  text-align: center;
  background: var(--light);
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  border-left: 1px solid whitesmoke;
  border-right: none;
  flex: 1;
  &:nth-last-child(-n + 1) {
    border-right: 1px solid whitesmoke;
  }
  &.active {
    border-bottom: 2px solid var(--primary);
  }
`;

const TabContentWrap = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  padding: var(--sectionPadding);
  background: #fbfbfb;
  border: 1px solid whitesmoke;
  border-top: 0;
`;

const TabContent = styled.li`
  display: ${({ active }) => (active ? 'block' : 'none')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: 0.8s opacity;
`;

Tabs.defaultProps = {
  data: [
    {
      label: 'Tab 1',
      content: {
        heading: 'fingerprint',
        content: 'test',
      },
    },
    {
      label: 'Tab 2',
      content: {
        heading: 'Tab Content 2',
        content: 'Test',
      },
    },
    {
      label: 'Tab 3',
      content: {
        heading: 'Tab Content 3',
        content: 'test',
      },
    },
  ],
};

export default Tabs;
