import styled from 'styled-components';
import arrow from '../images/long-arrow-right.svg';

const LinkIcon = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 2px;
  height: 48px;
  border: 0;
  color: var(--primary);
  transition: all 0.3s;
  :after {
    content: '';
    display: inline-block;
    background: url(${arrow}) no-repeat;
    height: 17px;
    width: 20px;
    margin: 3px 0 0 4px;
  }
`;
export default LinkIcon;
