import React from 'react';
import SingleLineTextWithIcon from './SingleLineTextWithIcon';

const ListItem = ({ icon, text, link }) => {
  return (
    <li>
      <SingleLineTextWithIcon icon={icon} text={text} link={link} />
    </li>
  );
};

export default ListItem;
