import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Section from './Section';
import ButtonGradient from './Buttons/ButtonGradient';
import Text from './Styles/Text';
import Heading from './Styles/Heading';
import LinkIcon from './LinkIcon';
import Box from './Styles/Box';
import AngleDownIcon from './Icons/Fa/Thin/AngleDownIcon';

const PageHeader = (props) => {
  const {
    heading,
    subHeading,
    buttonText,
    buttonLink,
    arrow,
    link,
    linkText,
    className,
  } = props;

  const heroDiv = useRef(null);

  const scrollPage = (ref) => {
    if (window.scrollY >= ref.current.offsetHeight + 4) return;
    window.scrollTo({
      top: ref.current.offsetHeight + 4,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div ref={heroDiv}>
      <SectionStyle className={className}>
        <BoxStyles>
          {/* eslint-disable-next-line react/no-danger */}
          <Heading as="h1" dangerouslySetInnerHTML={{ __html: heading }} />
          <Text dangerouslySetInnerHTML={{ __html: subHeading }} />
          <div>
            {buttonText && (
              <Link to={buttonLink}>
                <ButtonGradient>{buttonText}</ButtonGradient>
              </Link>
            )}
            {linkText && (
              <Link to={link}>
                <LinkIcon>{linkText}</LinkIcon>
              </Link>
            )}
          </div>
          {arrow && (
            <div className="arrow">
              <span
                onClick={() => scrollPage(heroDiv)}
                role="button"
                tabIndex="0"
              >
                <AngleDownIcon />
              </span>
            </div>
          )}
        </BoxStyles>
      </SectionStyle>
    </div>
  );
};

const SectionStyle = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 8px 124px 8px;
  min-height: 65vh;
  background-size: cover;
  position: relative;
  a {
    margin: 0 8px;
  }
  .arrow {
    position: absolute;
    left: 0;
    bottom: 1em;
    width: 100%;
    text-align: center;
  }
  .arrow svg {
    width: 35px;
    transition: all 0.2s;
    cursor: pointer;
    :hover {
      transform: scale(1.04);
    }
  }
`;

const BoxStyles = styled(Box)`
  max-width: 100%;
  @media (min-width: 45em) {
    max-width: 70%;
  }
`;

PageHeader.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  arrow: PropTypes.bool,
  className: PropTypes.string,
};

PageHeader.defaultProps = {
  arrow: true,
  heading: '',
  subHeading: '',
  buttonText: '',
  buttonLink: '',
  link: '',
  linkText: '',
  className: '',
};

export default PageHeader;
