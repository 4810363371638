import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import Section from '../../components/Section';
import Grid from '../../components/Styles/Grid';
import Box from '../../components/Styles/Box';
import Flex from '../../components/Styles/Flex';
import Heading from '../../components/Styles/Heading';
import Text from '../../components/Styles/Text';
import Image from '../../components/Image';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import Faqs from '../../components/Faqs';
import Breadcrumbs from '../../components/Breadcrumbs';

import Tabs from '../../components/Tabs';

const WordPressDevelopmentPage = (props) => {
  const { data, location } = props;
  return (
    <Layout>
      <SEO title="Boston Web Development" />
      <PageHeader
        heading="Websites Made to Super Charge Your Business"
        subHeading="ALTdev focuses on building websites that are clean, user friendly, and mobile responsive. Using our unique approach we focus on your business needs and objectives to ensure that your new site leads to more conversions."
        buttonText="Request a Quote"
        buttonLink="/contact/"
        maxWidth={960}
        margin="auto"
      />

      <Section>
        <Heading>Our Process</Heading>
        <Tabs data={tabData} />
      </Section>

      <Section>
        <Breadcrumbs location={location} />
        <Grid
          gridTemplateColumns="repeat(2, 1fr)"
          gridGap={[2, 3]}
          alignItems="center"
          justifyItems="start"
        >
          <Box>
            <Heading as="h2">Ready to Super Charge your website?</Heading>
            <Text>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam
              tempore quas voluptatibus, fuga enim exercitationem! Laudantium
              voluptatum, at ducimus laboriosam officia perspiciatis rerum ipsam
              iure quae illum adipisci labore non.
            </Text>
            <List>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum, at ducimus laboriosam officia
                perspiciatis rerum ipsam iure quae illum adipisci labore non.
              </ListItem>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum.
              </ListItem>
            </List>
          </Box>
          <Box width="100%">
            <Image
              image={data.rocketDeveloper.childImageSharp.fluid}
              imageAltText="test"
              imageTitle="Test"
            />
          </Box>
        </Grid>
      </Section>

      <Section>
        <Box maxWidth={720} margin="0 auto 64px auto">
          <Heading as="h2" textAlign="center">
            We build feature-rich E-commerce applications that retain users.
          </Heading>
          <Text as="p" textAlign="center">
            Quos ipsam accusamus nihil provident veritatis magni minus
            accusantium quam nemo odit totam sequi, aspernatur maxime, ducimus
            error quaerat? Magnam maxime ipsa ex et quae minima sequi explicabo
            aperiam ratione esse sapiente deserunt, fuga laborum. Quaerat saepe
            architecto exercitationem magnam et!
          </Text>
        </Box>
        <Grid gridTemplateColumns="40% 1fr" gridGap={[2, 3]} alignItems="start">
          <Box mr={4}>
            <Heading as="h3">Magnam maxime ipsa ex et quae</Heading>
            <Text>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam
              tempore quas voluptatibus, fuga enim exercitationem! Laudantium
              voluptatum, at ducimus laboriosam officia perspiciatis rerum ipsam
              iure quae illum adipisci labore non.
            </Text>
            <List>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum, at ducimus laboriosam officia
                perspiciatis rerum ipsam iure quae illum adipisci labore non.
              </ListItem>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum.
              </ListItem>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum, at ducimus laboriosam officia
                perspiciatis rerum ipsam iure quae illum adipisci labore non.
              </ListItem>
            </List>
          </Box>
          <Box>
            <Heading as="h3">WordPress Frequently Asked Questions</Heading>
            <Faqs faqs={faqData} />
          </Box>
        </Grid>
      </Section>

      <Section>
        <Grid
          gridTemplateColumns="repeat(2, 1fr)"
          gridGap={[2, 3]}
          alignItems="start"
          justifyItems="start"
        >
          <Box mr={4}>
            <Heading as="h2">
              Feature-rich E-commerce applications that retain users.
            </Heading>
          </Box>
          <Box width="100%">
            <Text>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam
              tempore quas voluptatibus, fuga enim exercitationem! Laudantium
              voluptatum, at ducimus laboriosam officia perspiciatis rerum ipsam
              iure quae illum adipisci labore non.
            </Text>
            <List>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum, at ducimus laboriosam officia
                perspiciatis rerum ipsam iure quae illum adipisci labore non.
              </ListItem>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum.
              </ListItem>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum, at ducimus laboriosam officia
                perspiciatis rerum ipsam iure quae illum adipisci labore non.
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    rocketDeveloper: file(relativePath: { eq: "rocket-developer.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    contentMarketing: file(
      relativePath: { eq: "content-marketing_202307290.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gatsbyIcon: file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const faqData = [
  {
    question: 'What is WordPress and Do You Use It?',
    answer:
      'WordPress is a Content Management System (e.g., customers can manage their own website content if they want to) that currently powers over 28% of websites on the internet and this percentage continues to increase. Its popularity is due in part to two main factors 1) the ease in which additional functionality can be integrated and 2) customers can easily manage their content after the website is built. We do prefer to use the WordPress platform to build our websites. It not only ensures that our customers are set up to achieve their goals year over year as their business grows and their site can grow with them, it allows our customers to easily take over their own maintenance if they so desire.',
  },
  {
    question: 'How effective is a website for small businesses?',
    answer:
      'A website can help small businesses increase flexibility, control, branding and credibility. Eighty-four percent of U.S. consumers believe a business with a website is more credible than one that only has a social media page. To be credible and attract new customers, a website can make a difference.',
  },
  {
    question: 'Do I need a website for my small business?',
    answer:
      'The short answer is that there has never been a better or more important time to invest in a website for your business. A website offers a wide variety of benefits for small businesses, and most of these benefits increase in value exponentially year over year, just like the Internet itself.',
  },
  {
    question: 'How effective is a website for small businesses?',
    answer:
      'A website can help small businesses increase flexibility, control, branding and credibility. Eighty-four percent of U.S. consumers believe a business with a website is more credible than one that only has a social media page. To be credible and attract new customers, a website can make a difference.',
  },
];

const tabData = [
  {
    label: 'Discovery',
    icon: '1',
    content: {
      heading: 'Discovery',
      content:
        'We give the utmost importance to understand and document client’s inputs, design, and branding preferences. And clarify all the open-ended points to establish a precise and clear idea about both clients’ and project’s goals. We work in a collaborative approach involving key stakeholders to bring alignment in the business process.',
    },
  },
  {
    label: 'Planning',
    icon: '2',
    content: {
      heading: 'Planning',
      content:
        'Our services emphasize on well-defined project planning and leave nothing for assumption to avoid miscommunication. We jot down how we are going to proceed further, define detailed technical specifications, devise the design & branding guidelines of the project, and get confirmation and approval from the clients’ end on the documents and materials.',
    },
  },
  {
    label: 'Design',
    icon: '3',
    content: {
      heading: 'Design',
      content:
        'We provide clients with Web, Tablet, and Mobile-specific UI designs of the projects based on the approved wireframes and design guidelines. As a trusted entity, we deliver clickable prototypes on Invision platform and the source files in either Photoshop or Sketch format. Getting system UI designs approved and confirmed by the client is one of our objectives at this stage.',
    },
  },
  {
    label: 'Development',
    icon: '4',
    content: {
      heading: 'Development',
      content:
        'At this stage, we cater requirements for frontend, backend, web services, and API development integration. Along with preparing a strategy for Agile Scrum methodology, we factor the aspects of scalability, multi-tenancy, 3rd party integration, and craft an optimized clean code structure using cutting-edge technologies. We make sure to involve and implement clients’ reviews in each sprint/milestone.',
    },
  },
  {
    label: 'Testing',
    icon: '5',
    content: {
      heading: 'Testing',
      content:
        'We test each sprint manually, report bugs and add those to product backlog. Once the bug fixing is done, we provide the quality release and then the final demo of the sprint is sent for clients’ approval. We make sure to do regression testing to ensure proper functioning of the previously approved milestones/sprints.',
    },
  },
  {
    label: 'Maintenance',
    icon: '6',
    content: {
      heading: 'Maintenance',
      content:
        'Our work doesn’t end with development, we go the extra mile for our clients. Through an Agile approach, we ensure product enhancement. We do regular Security Audits, Bi-weekly code backups, and constant system upgradation. Along with testing the entire system each month to find any incompatibility or error, we monitor the traffic and server load and accordingly optimize it.',
    },
  },
];

export default WordPressDevelopmentPage;
