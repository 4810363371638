import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Inner from '../components/Styles/Inner';
import Section from '../components/Section';
import Heading from '../components/Styles/Heading';
import Text from '../components/Styles/Text';
import Flex from '../components/Styles/Flex';
import Box from '../components/Styles/Box';
import FaqItem from '../components/FaqItem';

function Faqs(props) {
  const { faqs } = props;

  return (
    <Box>
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </Box>
  );
}

Faqs.propTypes = {
  faqs: PropTypes.array,
};

export default Faqs;
