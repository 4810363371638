import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { space, layout, color, typography } from 'styled-system';

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  ${color};
  ${space};
  ${layout};
  ${typography};
`;

List.propTypes = {
  ...space.propTypes,
  ...layout.propTypes,
  ...color.propTypes,
};

export default List;
