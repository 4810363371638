import styled from 'styled-components';
import { color, space, typography, layout } from 'styled-system';

const Text = styled.p`
  ${typography};
  ${color};
  ${space};
  ${layout};
`;

export default Text;
